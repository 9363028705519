<template>
  <button><a href="https://hudplejeklinikken-esbjerg.planway.com/">Booking</a></button>
</template>

<script>
export default {
  name: 'BookingComp',
}
</script>

<style scoped lang="scss">
@import "../styles/_variables.scss";

button {
  
  border: 1px solid black;
  border-radius: 5px;
  background-color: #50362c;
  padding: 5px;
  // margin-right: 40px !important;
  box-shadow: 2px 2px 5px 1px map-get($cs, black);

  a {
    font-family: Kohinoor Bangla Lig;
    color: map-get($cs, wtext);
    text-decoration: none;
    font-size: 25px;
  }

  &:hover {
    background-color: map-get($cs, logocol2);
    border: 1px solid map-get($cs, logocol2);

    a {
      color: map-get($cs, logocol);
    }
  }

  @media (max-width: 800px) {
    height: 40px !important;
    padding: 0 50px;

    a {
      font-size: 20px;
    }
  }

  @media (max-width: 500px) {
    // height: 20px !important;
    padding: 0 40px;
    margin-right: 0px !important;

    a {
      font-size: 18px;
    }
  }
}</style>
