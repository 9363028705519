import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/saleview',
    name: 'saleview',
    component: () => import('../components/sales/SaleView.vue')
  },
  {
    path: '/treatments',
    name: 'treatments',
    component: () => import('../views/TreatmentsView.vue')
  },
  // {
  //   path: '/temp',
  //   name: 'temp',
  //   component: () => import('../views/TreatmentsTempInternView.vue')
  // },
  {
    path: '/facial',
    name: 'facial',
    component: () => import('../views/TreatmentsFacialView.vue')
  },
  {
    path: '/special',
    name: 'special',
    component: () => import('../views/TreatmentsSpecialView.vue')
  },
  {
    path: '/fire&ice',
    name: 'fire&ice',
    component: () => import('../views/TreatmentsFire&IceView.vue')
  },
  {
    path: '/hydrafacial',
    name: 'hydrafacial',
    component: () => import('../views/TreatmentsHydraView.vue')
  },
  {
    path: '/beautyangel',
    name: 'beautyangel',
    component: () => import('../views/TreatmentsBeautyAngelView.vue')
  },
  {
    path: '/skinpen',
    name: 'skinpen',
    component: () => import('../views/TreatmentsSkinpenView.vue')
  },
  {
    path: '/hairremoval',
    name: 'hairremoval',
    component: () => import('../views/TreatmentsHairRemovalView.vue')
  },
  {
    path: '/sugaring',
    name: 'sugaring',
    component: () => import('../views/TreatmentsSugaringView.vue')
  },
  {
    path: '/browslashes',
    name: 'browslashes',
    component: () => import('../views/TreatmentsBrowsLashesView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/clinic',
    name: 'clinic',
    component: () => import('../views/AboutClinicView.vue')
  },
  {
    path: '/staff',
    name: 'staff',
    component: () => import('../views/AboutStaffView.vue')
  },
  {
    path: '/brands',
    name: 'brands',
    component: () => import('../views/AboutBrandsView.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/AboutProductsView.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('../views/AboutPolicyView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/faceperfection',
    name: 'faceperfection',
    component: () => import('../components/special/FacePerfection.vue')
  },
  {
    path: '/hydraperfection',
    name: 'hydraperfection',
    component: () => import('../components/special/HydraPerfection.vue')
  },
  {
    path: '/defencesolution',
    name: 'defencesolution',
    component: () => import('../components/special/DefenceSolution.vue')
  },
  {
    path: '/liftcreator',
    name: 'liftcreator',
    component: () => import('../components/special/LiftCreator.vue')
  },
  {
    path: '/fillercode',
    name: 'fillercode',
    component: () => import('../components/special/FillerCode.vue')
  },
  {
    path: '/prodigio',
    name: 'prodigio',
    component: () => import('../components/special/ProdigiO.vue')
  },
  {
    path: '/agemethod',
    name: 'agemethod',
    component: () => import('../components/special/AgeMethod.vue')
  },
  {
    path: '/whitescience',
    name: 'whitescience',
    component: () => import('../components/special/WhiteScience.vue')
  },
  {
    path: '/procellular365',
    name: 'procellular365',
    component: () => import('../components/special/ProCellular365.vue')
  },
  {
    path: '/acidinfusion',
    name: 'acidinfusion',
    component: () => import('../components/special/AcidInfusion.vue')
  },
  {
    path: '/thegold',
    name: 'thegold',
    component: () => import('../components/special/TheGold.vue')
  },
  {
    path: '/pureequalizer',
    name: 'pureequalizer',
    component: () => import('../components/special/PureEqualizer.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior () {
    return { top: 0 }
  },
})

export default router
