<template>
    <div id="fpimgcont">
        <div id="fpimg"></div>
        <div id="p" class="fade-in" style="animation-duration: 650ms;">
            <p id="p1">Hudplejeklinikken Esbjerg tilbyder professionelle hudbehandlinger i rolige og afslappende omgivelser
                ved
                Behandlergården i hjertet af Esbjerg.
            </p>
            <br>
            <p id="p2">Alle behandlinger er nøje udvalgt og sammensat, og udføres af uddannet
                sygeplejerske og kosmetolog.</p>
            <br>
            <p id="p3">Maskiner, udstyr og produkter er valgt med stor omhu, for kun det bedste er godt
                nok
                til mine kunder. </p>
            <br>
            <p id="p4">Velkommen hos Hudplejeklinikken Esbjerg.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterComp',

}
</script>

<style scoped lang="scss">
@import "../styles/_variables.scss";

#fpimgcont {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    #fpimg {
        width: 100%;
        position: relative;
        // background-image: url("https://images.pexels.com/photos/3762871/pexels-photo-3762871.jpeg");
        // background-image: url("../assets/fpimage.jpg");
        // background-image: url("https://i.imgur.com/csPBdCx.png");
        //background-image: url("https://i.imgur.com/OO9K3Ig.jpg");
        // background-image: url("https://i.imgur.com/nadReNR.jpg"); // COMPRESSED
        // background-image: url("https://i.imgur.com/gN8JZ6h.jpg"); // ANNA's
        background-image: url("https://i.imgur.com/q4xL4XC.png"); // ANNA's v1 (brighter)
        min-height: 400px;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border: 1px solid map-get($cs, subtleborder);


    }

    #p {
        font-family: Kohinoor Bangla Lig;
        position: absolute;
        text-align: center;
        margin: auto 0;
        padding: 10px;
        width: 40%;
        // background-color: map-get($cs, fpimgtxtbg);
        // background-color: #664a4460;
        border-radius: 8px;
        background-color: map-get($cs, transparentbg3);
        
        p {
            // color: map-get($cs, fpimgtxtcol);
            color: map-get($cs, logocol);
            // color: #ffffff;
            text-shadow: 1px 2px 5px rgba(126, 126, 126, 0.216);
            // -webkit-text-stroke: 1px map-get($cs, fpimgtxtstrkcol);
            -webkit-text-stroke: 1px rgba(0, 0, 0, 0.142);

        }

        #p1 {
            font-size: 20px;
        }

        #p2 {
            font-size: 18px;
        }

        #p3 {
            font-size: 18px;
        }

        #p4 {
            font-size: 20px;

        }


    }


    @media (max-width: 1100px) {
        #fpimg {
            background-position: right;
        }
        #p {

            #p1 {
                font-size: 18px;
            }

            #p2 {
                font-size: 16px;
            }

            #p3 {
                font-size: 16px;
            }

            #p4 {
                font-size: 18px;

            }
        }
    }

    @media (max-width: 800px) {

        #fpimg {
            min-height: 300px !important;
            background-position: right;
        }

        #p {
            width: 60% !important;

            #p1 {
                font-size: 13px;
            }

            #p2 {
                font-size: 12px;
            }

            #p3 {
                font-size: 12px;
            }

            #p4 {
                font-size: 13px;

            }
        }
    }

    @media (max-width: 500px) {
        #fpimg {
            background-image: url("https://i.imgur.com/nadReNR.jpg");
        }
        #p {
            width: 70% !important;

            #p1 {
                font-size: 12px;
            }

            #p2 {
                font-size: 11px;
            }

            #p3 {
                font-size: 11px;
            }

            #p4 {
                font-size: 12px;

            }
        }
    }
}
</style>
