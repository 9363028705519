<template>
    <nav id="nav">
        <div id="logo">
            <router-link to="/">
                <Logo />
            </router-link>
        </div>
        <div id="bar">
            <router-link to="/">Forside</router-link>
            <div class="dropdown">
                <div class="dropbtn"><router-link to="/about">Om Os</router-link></div>
                <div class="dropdown-content">
                    <router-link to="/clinic" class="fade-in" style="animation-duration: 450ms;">Klinikken</router-link>
                    <router-link to="/staff" class="fade-in" style="animation-duration: 550ms;">Personale</router-link>
                    <router-link to="/brands" class="fade-in" style="animation-duration: 650ms;">Mærker</router-link>
                    <router-link to="/products" class="fade-in" style="animation-duration: 750ms;">Køb af Gavekort & Produkter</router-link>
                    <router-link to="/policy" class="fade-in" style="animation-duration: 850ms;">Handelsbetingelser</router-link>
                </div>
            </div>
            <div class="dropdown">
                <div class="dropbtn"><router-link to="/treatments">Behandlinger</router-link></div>
                <div class="dropdown-content">
                    <!-- <router-link to="/temp">Midlertidig Elevbehandlinger</router-link> -->
                    <router-link to="/facial" class="fade-in" style="animation-duration: 450ms;">Ansigtsbehandlinger & Dybderens</router-link>
                    <router-link to="/special" class="fade-in" style="animation-duration: 550ms;">Dibi Milano Specialbehandlinger</router-link>
                    <router-link to="/fire&ice" class="fade-in" style="animation-duration: 650ms;">iS CLINICAL Specialbehandlinger</router-link>
                    <router-link to="/hydrafacial" class="fade-in" style="animation-duration: 750ms;">Hydrafacial</router-link>
                    <router-link to="/beautyangel" class="fade-in" style="animation-duration: 850ms;">Beauty Angel</router-link>
                    <router-link to="/skinpen" class="fade-in" style="animation-duration: 950ms;">SkinPen</router-link>
                    <router-link to="/hairremoval" class="fade-in" style="animation-duration: 1050ms;">Voksbehandlinger</router-link>
                    <router-link to="/sugaring" class="fade-in" style="animation-duration: 1150ms;">Sugaring</router-link>
                    <router-link to="/browslashes" class="fade-in" style="animation-duration: 1250ms;">Vipper & Bryn</router-link>
                </div>
            </div>

            <router-link to="/contact">Kontakt</router-link>
        </div>
        <div id="booking">
            <Booking />
        </div>
    </nav>
    <!-- <div id="booking2">
        <Booking />
    </div> -->
</template>

<script>
import Logo from './Logo.vue';
import Booking from './Booking.vue';

export default {
    setup() {


        return {}
    },
    name: 'NavComp',
    components: {
        Logo,
        Booking,
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";

#nav {
    height: 100px;
    margin: 10px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid map-get($cs, subtleborder);

    #logo {
        width: 100px;
        display: flex;
        justify-self: flex-start;

    }

    #bar {
        border-radius: 5px;
        padding: 2px 5px;

        a {
            transition: border-bottom 2s ease-out 100ms;
            border-radius: 5px;
            padding: 0px 15px;
            font-size: 20px;
            font-family: Kohinoor Bangla Lig !important;

            &:hover {
                border-bottom: 1px solid #8b4b24;
            }

        }

        .dropbtn {
            border: none;
        }

        .dropdown {
            position: relative;
            display: inline-block;
        }

        .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f1f1f1;
            min-width: 200px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            top: 31px;
        }

        .dropdown-content a {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
        }

        .dropdown-content a:hover {
            background-color: #ddd;
        }

        .dropdown:hover .dropdown-content {
            display: block;
            @media (max-width: 800px) {
                display: none;
            }
        }

        .dropdown:hover .dropbtn {
            // background-color: #3e8e41;
        }

    }

    #booking {
        // width: 100px;
        display: flex;
        justify-content: flex-end !important;
       
    }


    a {
        font-weight: bold;
        color: #50362c;
        text-decoration: none;

        &.router-link-exact-active {
            color: map-get($cs, highlight);
        }
    }

    @media (max-width: 1100px) {
        #logo {
            img {
                height: 60px !important;
            }
        }

        #bar {
            a {
                padding: 2px 5px;
                font-size: 16px;
            }
        }
    }

    @media (max-width: 800px) {
        flex-direction: column;

        #logo {
            width: 100px;
            display: flex;
            justify-content: center;
        }

        #bar {
            display: flex;
            flex-direction: row;
            align-items: center;

            a {
                padding: 2px 5px;
            }
        }

        #booking {
            display: none;
        }

    }

    @media (max-width: 500px) {
        flex-direction: column;

        #logo {
            width: 100px;
            display: flex;
            justify-content: center;
        }

        #bar {
            display: flex;
            flex-direction: row;
            align-items: center;

            a {
                padding: 2px 5px;
            }
        }

        //     #booking {
        //         position: absolute;
        //         bottom: 0;
        //         right: auto;
        //     }
    }

}


</style>