<template>
    <img alt="hpke logo" src="../assets/logo.jpg" height="80">    
</template>

<script>
export default {
  name: 'LogoComp',
  props: {
    msg: String
  }
}
</script>

<style scoped lang="scss">
@import "../styles/_variables.scss";

</style>
