<template>
    <div class="footer">
        <div id="footercon">
            <div id="footer-left">
                <div class="footer-section head">
                    <p>Kontaktoplysninger</p>
                </div>
                <div class="footer-section">
                    <div id="icon"><span class="material-symbols-outlined">
                            home
                        </span></div>
                    <p>Hudplejeklinikken Esbjerg</p>
                </div>
                <div class="footer-section">
                    <div id="icon"><span class="material-symbols-outlined">
                            favorite
                        </span></div>
                    <p>V/ sygeplejerske og kosmetolog Pia Ahle</p>
                </div>
                <div class="footer-section">
                    <div id="icon"><span class="material-symbols-outlined">
                            tag
                        </span></div>
                    <p>CVR: 43881612</p>
                </div>
                <div class="footer-section">
                    <div id="icon"><span class="material-symbols-outlined">
                            signpost
                        </span></div>
                    <p>Bjarkesgade 13 - 6700 Esbjerg</p>
                </div>
                <div class="footer-section">
                    <div id="icon"><span class="material-symbols-outlined">
                            call
                        </span></div>
                    <p><a href="tel:93933770">TLF: +(45) 93 93 37 70</a></p>
                </div>
                <div class="footer-section">
                    <div id="icon"><span class="material-symbols-outlined">
                            alternate_email
                        </span></div>
                    <p><a href="mailto:info@hudplejeklinikken-esbjerg.dk">info@hudplejeklinikken-esbjerg.dk</a></p>
                </div>
                <div class="footer-section">
                    <div id="icon"><span class="material-symbols-outlined">
                            language
                        </span></div><a target="_blank" href="https://www.hudplejeklinikken-esbjerg.dk">www.hudplejeklinikken-esbjerg.dk</a>
                </div>
            </div>
            <div id="footer-right">
                <div class="footer-section head">
                    <p>Åbningstider</p>
                </div>
                <div class="footer-section">
                    <p id="day">Mandag:</p>
                    <!-- <p>9 - 16</p> -->
                    <p>Efter aftale</p>
                </div>
                <div class="footer-section">
                    <p id="day">Tirsdag:</p>
                    <!-- <p>10 - 18</p> -->
                    <p>Efter aftale</p>
                </div>
                <div class="footer-section">
                    <p id="day">Onsdag:</p>
                    <!-- <p>9 - 16</p> -->
                    <p>Efter aftale</p>
                </div>
                <div class="footer-section">
                    <p id="day">Torsdag:</p>
                    <!-- <p>9 - 16</p> -->
                    <p>Efter aftale</p>
                </div>
                <div class="footer-section">
                    <p id="day">Fredag:</p>
                    <!-- <p>9 - 16</p> -->
                    <p>Efter aftale</p>
                </div>
                <!-- <div class="footer-section" >
                    <p id="day">Torsdag:</p>
                    <p style="position: relative;">10 - 20
                    <b style="position: absolute;left:110%; top:-3px;font-size: 20px; font-weight: normal; margin-left: 10px; max-width:120px;width:120px;">*</b>
                    <b style="position: absolute;left:110%; top:3px;font-size: 11px; font-weight: normal; margin-left: 10px; max-width:120px;width:120px;">(Produktsalg 10-18)</b>
                    </p>
                </div>
                <div class="footer-section">
                    <p id="day">Fredag:</p>
                    <p style="position: relative;">
                        <b style="color:#664A44;">space</b>
                    <b style="position: absolute; z-index:2;left:-50%; top:-3px;font-size: 15px; font-weight: normal; margin-left: 10px; max-width:120px;width:120px;">Efter</b>
                    <b style="position: absolute;z-index:2;left:50%; top:-3px;font-size: 15px; font-weight: normal; margin-left: 10px; max-width:120px;width:120px;"> aftale</b>
                    </p>
                </div> -->
                <div class="footer-section">
                    <p id="day">Lørdag:</p>
                    <p>Lukket</p>
                </div>
                <div class="footer-section">
                    <p id="day">Søndag:</p>
                    <p>Lukket</p>
                </div>
                <!-- <div class="footer-section">
                    <p id="day" style="font-size: 12px;">*Salg af produkter og gavekort kun 10-18</p>
                    
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterComp',

}
</script>

<style scoped lang="scss">
@import "../styles/_variables.scss";

.footer {
    background-color: map-get($cs, logocol);

    #footercon {
        max-width: 90%;
        margin: 0 auto !important;
        display: flex;
        justify-content: space-between;
        // background-color: map-get($cs, fpimgtxtbg);
        background-color: map-get($cs, logocol);

        #footer-left {
            flex-grow: 1;
            flex-direction: column;
            margin-left:100px !important;
            padding: 15px;
            padding-bottom: 60px !important;
            bottom: 0;
            @media (max-width:1100px) {
                margin-left:0 !important;
            }
            .head {

                p,
                a {
                    font-size: 20px !important;
                }
            }

            .footer-section {
                display: flex;
                margin-bottom: 2px !important;
                align-items: center;

                #icon {
                    margin-right: 10px !important;
                    font-size: 10px;
                    color: map-get($cs, wtext);
                }

                p,
                a {
                    text-align: left;
                    font-size: 10px;
                    color: map-get($cs, wtext);
                    font-family: Kohinoor Bangla Lig !important;
                    letter-spacing: 1px;
                }

                a {
                    &:hover {
                        color: map-get($cs, highlight);
                    }
                }
            }
        }

        #footer-right {
            flex-grow: 1;
            flex-direction: column;
            justify-content: center;
            padding: 15px;
            padding-bottom: 60px !important;
            bottom: 0;
            // margin: 0 25% !important;

            .head {
                // justify-content: flex-start !important;
                display:flex !important; 
                justify-content: center !important;                 
                p,
                a {
                    font-size: 20px !important;
                }
            }

            .footer-section {
                margin-bottom: 2px !important;
                display: flex;
                // justify-content: space-evenly;
                justify-content: space-between;
                margin: 0 25% !important;

                #day {
                    margin-right: 10px !important;
                    font-size: 10px;
                    color: map-get($cs, wtext);
                    margin-bottom: 10px !important;
                }

                p,
                a {
                    text-align: left;
                    font-size: 10px;
                    color: map-get($cs, wtext);
                    font-family: Kohinoor Bangla Lig !important;
                    letter-spacing: 1px;
                }

                a {
                    &:hover {
                        color: map-get($cs, highlight);
                    }
                }
            }
        }

        @media (max-width:500px) {
            flex-direction: column;
            #footer-left {
                margin: 0 auto !important;
                padding-bottom: 25px !important;
                .head {
                    justify-content: center !important;
                }
            }

            #footer-right {
                // margin: 0 auto !important;
                padding-bottom: 80px !important;
                .footer-section {
                    justify-content: space-between !important;
                }
            }

            #footer-right,
            #footer-left {
                // padding-bottom: 20px;



                .footer-section {
                    margin-bottom: 2px !important;
                    display: flex;
                    // justify-content: space-between !important;

                    #day {
                        margin-right: 5px !important;
                        font-size: 14px !important;
                        color: map-get($cs, wtext);
                        margin-bottom: 5px !important;
                    }

                    #icon {
                        margin-right: 5px !important;
                        color: map-get($cs, wtext);
                        margin-bottom: 5px !important;
                        height: 15px;

                        span {
                            font-size: 16px;
                        }
                    }

                    p,
                    a {
                        text-align: left;
                        font-size: 14px !important;
                        color: map-get($cs, wtext);
                        font-family: Kohinoor Bangla Lig !important;
                        letter-spacing: 1px;
                        // line-height: 12px;
                    }
                }

                .head {

                    p,
                    a {
                        font-size: 18px !important;
                    }
                }
            }
        }
    }
}
</style>
